import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../components/Website/ProjectImagesSwiper";
import ProjectImgWrapper from "../../components/Common/ImgWrapper";

const Linq = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Broadway Podcasts"
      description="Broadway Podcasts are experts in wine consultancy."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="mb-5 bk-about-area">
            <Col md={6} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Linq</h2>
                  </div>
                  <ServicePellet service="UI & UX" />

                  <p class="wow move-up mb--20">
                    Modern networking. - Share stunning, customized profiles
                    with a tap.
                  </p>
                  <p class="wow move-up mb--20">
                    Linq is a networking platform that allows users to share
                    information with others using completely customizable pages.
                    It’s easy to share your Linq remotely, and with one of
                    Linq’s physical products.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12}>
              <Img fluid={imagesData[22]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              {imagesData.slice(0, 4).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(0, 4)} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 my-lg-0 d-none d-md-flex">
              {imagesData.slice(4, 8).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(4, 8)} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              {imagesData.slice(8, 12).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(8, 12)} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              {imagesData.slice(12, 16).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(12, 16)} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              {imagesData.slice(4, 8).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(12, 16)} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              {imagesData.slice(16, 20).map((img, id) => (
                <Col md={3} xs={12}>
                  <ProjectImgWrapper>
                    <Img fluid={img} />
                  </ProjectImgWrapper>
                </Col>
              ))}
            </Row>
            <ProjectImagesSwiper imagesList={imagesData.slice(16, 20)} />
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="my-5" style={{ maxWidth: 840, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[20]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5" style={{ maxWidth: 840, margin: "auto" }}>
              <Col xs={12}>
                <Img fluid={imagesData[21]} />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center ">
              <CompanyLogo name="linq" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Skippit App", link: "/work/skippit" }}
            prevProject={{
              name: "Wines From Another World",
              link: "/work/winesfromanotherworld",
            }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Linq;

export const BroadwayAppQuery = graphql`
  query LinqPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/linq/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
